.leaflet-control-attribution{
    display: none !important;
}
.leaflet-control-zoom-in{
    background-color: blue !important;
    color: white !important;
    border-radius: 5px 5px 1px 1px !important; 
}
.leaflet-control-zoom-out{
    background-color: blue !important;
    color: white !important;
    border-radius: 1px 1px 5px 5px !important; 
}
.leaflet-bar{
    border: none !important;
}
.leaflet-container{
    background-color: white;
}
.leaflet-popup {
    font-size: 10px;
}
.my-popup {
    pointer-events: none !important;
    top: -70px;
    left: 0;
}
.overview-height {
    min-height: 150px;
}
.inline-input-holder .icon{
    display: none;
}
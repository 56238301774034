.card-container {
  border-radius: 4px;
  border: 1px solid var(--Orange__6);
  background-color: var(--Background__8);
}

.card-header {
  background-color: var(--Orange__6);
  .card-sport-icon {
    left: 12px;
  }
}

.out-of-package {
  height: 42px;
  background-color: var(--primary-bg-3);
}

.card-price-container {
  border-left: 1px solid var(--gray-background);
}

.card-select-container {
  border-top: 1px solid var(--gray-background);
}

.vs-holder {
  width: 26px;
  border-radius: 50%;
  background-color: var(--white-cream);
}

.card-close-icon {
  border-radius: 50%;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  background-color: var(--white-cream);
  border: 1px solid var(--dark-gray);
  &:hover {
    border: 1px solid var(--vivid-cerulean) !important;
    .i {
      color: var(--vivid-cerulean) !important;
    }
  }
}

.out-of-package {
  background-color: var(--Background__8);
}

.card-text {
  opacity: 80%;
}

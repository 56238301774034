.booking-countries-popup {
  max-height: 628px;

  .input-holder {
    .input-element-back {
      border-radius: 6px;
    }
  }
}

.booking-countries-content {
  overflow: auto;
  max-height: 500px;
}

.popup-item {
  border: 1px solid var(--primary-1);
  border-radius: 6px;
}

.currency {
  color: var(--primary-1) !important;
}

.search-filed {
  background-color: var(--ethereal-mist) !important;
  .input-label-container {
    display: none;
  }

  .input-structure {
    height: 32px;
  }
}

.unbook-button {
  height: 30px;
}

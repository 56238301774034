.unbook {
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 5;
}

.unbook-disable {
    opacity: 50%;
    pointer-events: none;
}
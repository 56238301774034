@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('./assets/fonts/icomoon/style.css');
@import url('./assets/fonts/world-icons/style.css');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
html {
  font-size: 14px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	overflow-x: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.rdrDefinedRangesWrapper {
	display: none;
}
a {
	text-decoration: none;
}

/* * {
  scrollbar-width: thin;
  scrollbar-color: #ececf1 #ececf1;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
  display: none;
}
::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 16px;
  background: #ececf1 content-box;
}
::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active {
  background-color: #ececf1;
}
::-webkit-scrollbar-track {
  background: transparent;
} */

::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #F1F4FA;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #F1F4FA;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display:none;
}

.cart-toast {
	border-left: 5px solid #1DBB2D;
	border-radius: 5px;
}

/* animations */

@keyframes scale-open {
	from { transform: scale(0); }
	to { transform: scale(1); }
}


@keyframes waves {
	to {
		box-shadow: 0 0 0 0.1rem rgba(255,189,1,0.2),
		0 0 0 0.3rem rgba(255,189,1,0.2),
		0 0 0 .5rem rgba(255,189,1,0.2),
		0 0 0 0.7rem rgba(255,189,1,0.2)
	}
}
@keyframes scale-open-big {
	from { transform: scale(2); }
	to { transform: scale(1); }
}

@keyframes rightToLeft {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}

@keyframes topToBottom {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(47px);
	}
}

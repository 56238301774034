.header-item {
  background-color: var(--white);
}

.left-border {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.right-border {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.header-sticky {
  position: sticky;
  z-index: 1;
  top: -10px;
}

.header-group-title {
  position: sticky;
  top: 24px;
  z-index: 1;
  background: var(--table-header-gray);
}

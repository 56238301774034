.error-text {
    line-height: 1.3;
}
.error-def-text {
    height: 180px;
    color: var(--primary-bg-5);
    border-radius: 6px;
    overflow: hidden;
    gap: 8px;
  
    @media (max-width: 1600px) {
      height: 180px;
    }
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3kcd6e');
  src:  url('fonts/icomoon.eot?3kcd6e#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?3kcd6e') format('woff2'),
    url('fonts/icomoon.ttf?3kcd6e') format('truetype'),
    url('fonts/icomoon.woff?3kcd6e') format('woff'),
    url('fonts/icomoon.svg?3kcd6e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e965";
}
.icon-cancel-match:before {
  content: "\e960";
}
.icon-start-streaming:before {
  content: "\e961";
}
.icon-end-streaming:before {
  content: "\e962";
}
.icon-start-match:before {
  content: "\e963";
}
.icon-end-match:before {
  content: "\e964";
}
.icon-ott:before {
  content: "\e95f";
}
.icon-comparison-down:before {
  content: "\e95d";
}
.icon-comparison-up:before {
  content: "\e95e";
}
.icon-cc-jcb:before {
  content: "\e955";
}
.icon-cc-mastercard:before {
  content: "\e956";
}
.icon-cc-amex:before {
  content: "\e957";
}
.icon-cc-maestro:before {
  content: "\e958";
}
.icon-cc-paypal:before {
  content: "\e959";
}
.icon-cc-diners-club:before {
  content: "\e95a";
}
.icon-cc-discover:before {
  content: "\e95b";
}
.icon-cc-visa:before {
  content: "\e95c";
}
.icon-friendship-logo-spaced:before {
  content: "\e953";
}
.icon-streaming-logo:before {
  content: "\e954";
}
.icon-scouting-mini-logo:before {
  content: "\e951";
}
.icon-friendship-mini-logo:before {
  content: "\e952";
}
.icon-friendship-logo:before {
  content: "\e901";
}
.icon-scouting-logo:before {
  content: "\e950";
}
.icon-wallet:before {
  content: "\e94f";
}
.icon-spinner:before {
  content: "\e94d";
}
.icon-file-type:before {
  content: "\e94c";
}
.icon-error-outline:before {
  content: "\e947";
}
.icon-log-history:before {
  content: "\e948";
}
.icon-copy-link:before {
  content: "\e949";
}
.icon-ticket:before {
  content: "\e94a";
}
.icon-success-outline:before {
  content: "\e94b";
}
.icon-resend:before {
  content: "\e946";
}
.icon-betconstruct:before {
  content: "\e942";
}
.icon-backoffice:before {
  content: "\e943";
}
.icon-springbme:before {
  content: "\e944";
}
.icon-scoutdata:before {
  content: "\e945";
}
.icon-scouting:before {
  content: "\e945";
}
.icon-apps:before {
  content: "\e941";
}
.icon-clear-thick:before {
  content: "\e940";
}
.icon-arrow-right-line:before {
  content: "\e93f";
}
.icon-world:before {
  content: "\e93e";
}
.icon-games:before {
  content: "\e93c";
}
.icon-dot:before {
  content: "\e93d";
}
.icon-user-check:before {
  content: "\e93a";
}
.icon-user-alt-slash:before {
  content: "\e93b";
}
.icon-edit-pen:before {
  content: "\e939";
}
.icon-by-feedconstruct:before {
  content: "\e938";
}
.icon-save:before {
  content: "\e937";
}
.icon-files-and-links:before {
  content: "\e935";
}
.icon-clone:before {
  content: "\e936";
}
.icon-delete:before {
  content: "\e929";
}
.icon-edit:before {
  content: "\e92e";
}
.icon-view-doc:before {
  content: "\e930";
}
.icon-composite:before {
  content: "\e92a";
}
.icon-dynamic:before {
  content: "\e934";
}
.icon-success:before {
  content: "\e90f";
}
.icon-warning:before {
  content: "\e928";
}
.icon-info:before {
  content: "\e90e";
}
.icon-trash:before {
  content: "\e921";
}
.icon-error:before {
  content: "\e922";
}
.icon-collapsed:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e91b";
}
.icon-expanded:before {
  content: "\e907";
}
.icon-filter:before {
  content: "\e90c";
}
.icon-cursor:before {
  content: "\e91c";
}
.icon-more:before {
  content: "\e932";
}
.icon-play:before {
  content: "\e933";
}
.icon-table:before {
  content: "\e927";
}
.icon-map:before {
  content: "\e920";
}
.icon-chart-pie:before {
  content: "\e923";
}
.icon-chart-area:before {
  content: "\e924";
}
.icon-chart-bar:before {
  content: "\e925";
}
.icon-infinity:before {
  content: "\e91a";
}
.icon-address-book:before {
  content: "\e915";
}
.icon-drafting-compass:before {
  content: "\e916";
}
.icon-euro-sign:before {
  content: "\e919";
}
.icon-sign-out:before {
  content: "\e917";
}
.icon-video:before {
  content: "\e931";
}
.icon-check:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e92f";
}
.icon-calendar:before {
  content: "\e92b";
}
.icon-clock:before {
  content: "\e92c";
}
.icon-clear:before {
  content: "\e92d";
}
.icon-sorting-up:before {
  content: "\e91f";
}
.icon-sorting-down:before {
  content: "\e926";
}
.icon-logo-p-2:before {
  content: "\e911";
}
.icon-logo-p-1:before {
  content: "\e912";
}
.icon-arrow-left-outline:before {
  content: "\e903";
}
.icon-arrow-right-outline:before {
  content: "\e90b";
}
.icon-arrow-up-outline:before {
  content: "\e913";
}
.icon-arrow-down-outline:before {
  content: "\e914";
}
.icon-user-outline:before {
  content: "\e902";
}
.icon-user:before {
  content: "\e918";
}
.icon-users:before {
  content: "\e94e";
}
.icon-help:before {
  content: "\e900";
}
.icon-shopping-bag:before {
  content: "\e909";
}
.icon-export:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e90a";
}
.icon-minus:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e91e";
}
.icon-arrow-right:before {
  content: "\e91d";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-arrow-down:before {
  content: "\e90d";
}

.booking-status {
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.status-unbooked {
    background-color: var(--primary-5);
}

.status-partial-booked {
    background-color: var(--orange);
}

.status-fully-booked {
    background-color: var(--confirm-1);
}

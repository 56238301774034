
.video-js-player-box{
    height: 180px;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 1600px) {
        height: 180px;
    }
    .video-js {
      width: 100%;
      height: 100%;
    }
    .vjs-error-display {
      display: none;
    }
}
@font-face {
  font-family: 'world-icons';
  src:  url('fonts/world-icons.eot?ot37u3');
  src:  url('fonts/world-icons.eot?ot37u3#iefix') format('embedded-opentype'),
    url('fonts/world-icons.woff2?ot37u3') format('woff2'),
    url('fonts/world-icons.ttf?ot37u3') format('truetype'),
    url('fonts/world-icons.woff?ot37u3') format('woff'),
    url('fonts/world-icons.svg?ot37u3#world-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wi-"], [class*=" wi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'world-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wi-yerevan:before {
  content: "\e900";
}
.wi-stateunionofserbiaandmontenegro:before {
  content: "\e901";
}
.wi-southamerica:before {
  content: "\e902";
}
.wi-southernfederaldistrict:before {
  content: "\e903";
}
.wi-northcaucasianfederaldistrict:before {
  content: "\e904";
}
.wi-oceania:before {
  content: "\e905";
}
.wi-northwesternfederaldistrict:before {
  content: "\e906";
}
.wi-siberianfederaldistrict:before {
  content: "\e907";
}
.wi-dutcheastindies:before {
  content: "\e908";
}
.wi-eastgermany:before {
  content: "\e909";
}
.wi-europe:before {
  content: "\e90a";
}
.wi-fareasternfederaldistrict:before {
  content: "\e90b";
}
.wi-africa:before {
  content: "\e90c";
}
.wi-khabarovsk:before {
  content: "\e90d";
}
.wi-america:before {
  content: "\e90e";
}
.wi-americas:before {
  content: "\e90e";
}
.wi-moscow:before {
  content: "\e90f";
}
.wi-asia:before {
  content: "\e910";
}
.wi-northamerica:before {
  content: "\e911";
}
.wi-centralfederaldistrict:before {
  content: "\e912";
}
.wi-westindies:before {
  content: "\e913";
}
.wi-westgermany:before {
  content: "\e914";
}
.wi-tahiti:before {
  content: "\e915";
}
.wi-volgafederaldistrict:before {
  content: "\e916";
}
.wi-uralfederaldistrict:before {
  content: "\e917";
}
.wi-world:before {
  content: "\e918";
}
